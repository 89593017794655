var cookieName  = 'pdf_pages';
var cookie;
var sureReset   = 'Weet u zeker dat u alle pagina\'s uit de selectie wilt verwijderen?';

$(function(){

  // if(!Modernizr.input.placeholder){

    // $('[placeholder][type=text]').focus(function(){
      // var input = $(this);
      // if (input.val() == input.attr('placeholder')) {
        // input.val('');
        // input.removeClass('placeholder');
      // }
    // }).blur(function(){
      // var input = $(this);
      // if (input.val() == '' || input.val() == input.attr('placeholder')) {
        // input.addClass('placeholder');
        // input.val(input.attr('placeholder'));
      // }
    // }).blur();

    // $('[placeholder][type=text]').parents('form').submit(function(){
      // $(this).find('[placeholder][type=text]').each(function(){
        // var input = $(this);
        // if (input.val() == input.attr('placeholder')) {
          // input.val('');
        // }
      // })
    // });
  // }
  
  $('#reportlinks').change(function(){
    var href = $(this).find(':selected').attr('data-href');
    window.location = href;
  });
  
  // $('.table-wrapper:has(.table-overlay)').hover(function(){
    // var tableWidth  = $(this).children('table').width();
    // var outdentLeft = ($(this).width() - tableWidth) / 2;
    // $(this).children('div').hide();
    // $(this).children('table').css('width','100%');
    // $(this).css('width',tableWidth+'px').css('left',outdentLeft+'px').addClass('table-expanded');
  // },function(){
    // var divWidth = $(this).css('width');
    // $(this).children('div').show();
    // $(this).children('table').css('width',divWidth);
    // $(this).css('width','100%').css('left','0px').removeClass('table-expanded');
  // });
  
  // $('.tile .head').click(function(){
    // $(this).toggleClass('expand');
    // $(this).next().slideToggle(200);
  // });
  
  pdfInit();
  
});

function pdfInit() {
  
  if ($.cookie(cookieName) == null) {
    cookie = '';
  } else {
    cookie = $.cookie(cookieName);
    if (cookie.indexOf(';') != -1) $('#pdf-selection').removeClass('disabled');
  }
  
  $('#pdf-add:not(.disabled)').click(function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) == -1) {
      cookie += nodeInfo + ';';
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('#pdf-selection, #pdf-remove').removeClass('disabled');
      $(this).addClass('disabled');
    }
  }).each(function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) {
      $('#pdf-selection, #pdf-remove').removeClass('disabled');
      $(this).addClass('disabled');
    }
  });
  
  $('#pdf-remove').click(function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) {
      cookie = cookie.replace(nodeInfo + ';','');
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('#pdf-add').removeClass('disabled');
      $(this).addClass('disabled');
      if (cookie.indexOf(';') == -1) $('#pdf-selection').addClass('disabled');
    }
  });
  
  /*$('#reset').click(function(){
    if (confirm(sureReset)) {
      cookie = '';
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('#add-page').text($('#add-page').attr('data-add-text'));
      $(this).addClass('disabled');
      $('#selection').addClass('disabled');
    }
  });*/
  
  $('#pdf-selection').click(function(){
    if (cookie.indexOf(';') != -1) {
      var url = $(this).attr('data-urlstart');
      var nodes = cookie.split(';');
      for(var i=0; i<nodes.length-1; i++) {
        url += splitNodeInfo(nodes[i]).id + '-';
      }
      location.href = url;
    }
  });
  
}

function splitNodeInfo(value) {
  var array = value.split('|');
  return {id: array[0], title: array[1], url: array[2]};
}